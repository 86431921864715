import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {getPostsByCategory} from "../services/PostService";

import './PaginaAnno.css'
const PaginaAnno = () => {

    const [loading,setLoading] = useState(false);
    const [error, setError] = useState("");
    const [lezioni, setLezioni] = useState([])

    const navigate= useNavigate();
    

    const anniDict= {
        "1": "Prima",
        "2": "Seconda",
        "3": "Terza",
        "4": "Quarta",
        "5": "Quinta",
    }

    const subjects ={
      'matematica':{
        "1": ['Equazioni lineari', "Seconda"],
        "2": ['Sistemi lineari', "Seconda"],
        "3": ['Sistemi lineari', "Seconda"],
        "4": ['Sistemi lineari', "Seconda"],
        "5": ['Sistemi lineari', "Seconda"],
      },
      'fisica': {
        "1": ['Sistemi lineari', "Seconda"],
        "2": ['Sistemi lineari', "Seconda"],
        "3": ['Sistemi lineari', "Seconda"],
        "4": ['Sistemi lineari', "Seconda"],
        "5": ['Sistemi lineari', "Seconda"],
      }
    }

    const {materia, anno} = useParams();

    let currentSubjects = subjects[materia][anno];

    const fetchLezioni = async() => {
      let category = `${anniDict[anno]} ${materia}`

      let queryParams = {
        'fields': 'ID, title, post_thumbnail, tags'
      }

      try{
        const response = await getPostsByCategory(category, queryParams);
        setLezioni(response.data.posts);
      }catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
      
    }

    /* const url = `https://public-api.wordpress.com/rest/v1.1/sites/${process.env.REACT_APP_SITE_ID}/posts/?category=${anniDict[anno]} ${materia}` */

    useEffect(() => {

      fetchLezioni();

      /*   const fetchLezione = async () => {
          try {
            const response = await axios.get(url);
            setLezioni(response.data.posts);
          } catch (err) {
            setError(err);
          } finally {
            setLoading(false);
          }
        };
    
        fetchLezione(); */
      }, [anno]);
    

    return (
        <div className="content">
            <div className="post-list">
          
            {lezioni &&
                lezioni.map((item,i) => 
                    <div key={i} className="post" onClick={()=>{navigate(`/lezione/${Number(item.ID)}`)}}>
                    <img src={item?.post_thumbnail?.URL} />
                    <h4 className="post-title">{item.title}</h4>
                    </div>
                    
                )
            }
            </div>            
        </div>
    );
}

export default PaginaAnno; 