import React from "react";


import './Home.css'


const Home = () => {
    return (
        <div className="content home-content">
            <div className="home-title flex-column">
                <h2>FisicHub</h2>
                <p>Physics made easy</p>
            </div>
            <div className="division-bar"></div>
        </div>
    );
}

export default Home