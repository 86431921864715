import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';


const Footer = () =>{
    return (
        <div className='footer flex-row'>
            <div className='logo-title-footer-wrapper'>
                <div className='footer-logo-container bg-secondary'>
                    <img className="bg-secondary" src="/logo.png"></img>
                </div>
                <h3 className='bg-secondary margin-0'>FisicHub</h3>
                <p className='bg-secondary margin-0'>Physics made easy</p>
            </div>
            
            <div className='flex-row footer-links-container'>
                <div className='flex-column '>
                   {/*  <h3 className='bg-secondary'>Privacy</h3> */}
                    <p className='bg-secondary'>Privacy policy</p>
                    <p className='bg-secondary'>Contattaci</p>
                    <p className='bg-secondary'>Cookie policy</p>
                    <p className='bg-secondary'>Privacy policy</p>
                </div>
           {/*      <div className='flex-column'>
                    <h3 className='bg-secondary'>Privacy</h3>
                    <p className='bg-secondary'>Privacy policy</p>
                </div> */}
            </div>
        </div>
    );
}

export default Footer;